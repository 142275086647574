<template>
  <section
    v-if="useIsVisible(blok)"
    v-editable="blok"
    :class="
      getStyleSettings(blok) + ' ' + [blok.hide ? useIsEditorClass() : '']
    "
  >
    <div
      ref="benefitsNumbersSectionWrapper"
      :class="textColor"
      class="container"
    >
      <div class="grid grid-cols-1 gap-10 md:grid-cols-3 md:gap-10">
        <div class="text-center">
          <div
            class="benefits-numbers__counter benefits-numbers__counter--one text-h1 mb-3 md:mb-4"
            :style="{ '--numOneVal': blok.no1 }"
          >
            <span v-if="blok.prefix1 == true">+</span>
          </div>
          <span class="text-intro mx-auto block max-w-[200px] md:max-w-full">
            {{ blok.label1 }}
          </span>
        </div>
        <div class="text-center">
          <div
            class="benefits-numbers__counter benefits-numbers__counter--two text-h1 mb-3 md:mb-4"
            :style="{ '--numTwoVal': blok.no2 }"
          >
            <span v-if="blok.prefix2 == true">+</span>
          </div>
          <span class="text-intro mx-auto block max-w-[200px] md:max-w-full">
            {{ blok.label2 }}
          </span>
        </div>
        <div class="text-center">
          <div
            class="benefits-numbers__counter benefits-numbers__counter--three text-h1 mb-3 md:mb-4"
            :style="{ '--numThreeVal': blok.no3 }"
          >
            <span v-if="blok.prefix3 == true">+</span>
          </div>
          <span class="text-intro mx-auto block max-w-[200px] md:max-w-full">
            {{ blok.label3 }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import type { BenefitsNumbersStoryblok } from '@/types/component-types-sb'
import { useIntersectionObserver } from '@vueuse/core'

const benefitsNumbersSectionWrapper = ref<HTMLElement>()

const props = defineProps<{
  blok: BenefitsNumbersStoryblok
}>()

const textColor = computed(() => {
  return props.blok.background_color === 'black' ? 'text-white' : 'text-black'
})

useIntersectionObserver(
  benefitsNumbersSectionWrapper,
  ([{ isIntersecting }]) => {
    if (isIntersecting && benefitsNumbersSectionWrapper.value) {
      benefitsNumbersSectionWrapper.value.classList.add('animated')
    }
  },
  {
    threshold: 0.5,
  },
)
</script>

<style>
@property --num-one {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}
@property --num-two {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}
@property --num-three {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.benefits-numbers__counter--one {
  transition: --num-one 2s;
  counter-set: num-one var(--num-one);
}
.benefits-numbers__counter--one:after {
  content: counter(num-one);
}
.benefits-numbers__counter--two {
  transition: --num-two 3s;
  counter-set: num-two var(--num-two);
}
.benefits-numbers__counter--two:after {
  content: counter(num-two);
}
.benefits-numbers__counter--three {
  transition: --num-three 4s;
  counter-set: num-three var(--num-three);
}
.benefits-numbers__counter--three:after {
  content: counter(num-three);
}
.animated .benefits-numbers__counter {
  --num-one: var(--numOneVal);
  --num-two: var(--numTwoVal);
  --num-three: var(--numThreeVal);
}
</style>
